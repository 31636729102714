<template>
  <div>
    <div v-if="showLeadsDuplicates">
      <lead-duplicates />
    </div>
    <div v-else id="lead-table">
      <v-card
        style="margin: 2%; padding-top: 0px;"
        color=""
        class="mx-auto flexcard"
        elevation="0"
        height="100%"
        width="95%"
      >
        <v-row align="center" justify="center">
          <v-col cols="11 " class="justify-center">
            <LeadSearchField style="padding-top: 10px;" />
          </v-col>
        </v-row>
      </v-card>
      <v-row class="px-4 multiple-actions">
        <div class="export-clipboard">
          <lead-export-clipboard-button @select="selectAllLeads" />
        </div>
        <div v-if="role === 'admin'" class="mx-4 reassigned-multi">
          <leads-reassigned
            ref="refLeadReassigned"
            @close="onCloseReassignLeadModel"
          ></leads-reassigned>
        </div>
      </v-row>
      <div v-if="leadsPaginationLoadingSt && leads.length <= 0">
        <v-container class="ma-0 pa-0" fluid fill-height>
          <v-card
            elevation="0"
            class="ma-0 pa-0 mb-3"
            v-for="r in 15"
            :key="r"
            width="100%"
          >
            <v-row class="ma-0 pa-1" justify="center" align="center">
              <v-col cols="4" justify="center" align="center">
                <v-row>
                  <v-col cols="1">
                    <v-skeleton-loader type="text"> </v-skeleton-loader>
                  </v-col>
                  <v-col cols="2">
                    <v-skeleton-loader type="text"> </v-skeleton-loader>
                  </v-col>
                  <v-col cols="3" v-for="c in 3" :key="c">
                    <v-skeleton-loader type="text"> </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="8" justify="center" align="center">
                <v-row>
                  <v-col cols="2" v-for="c in 6" :key="c">
                    <v-skeleton-loader type="text"> </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </div>
      <div v-else-if="someFilterSelected && leads.length <= 0">
        <v-card width="100vw" height="90vh" elevation="0">
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col cols="12" justify="center" align="center">
                <div class="title">
                  No se encontraron Leads con los filtros seleccionados.
                </div>
              </v-col>
              <v-col cols="12" justify="center" align="center">
                <div class="subtitle-2 grey--text">
                  Intenta seleccionado otros filtros o puedes utilizar el
                  buscador que se encuentra en la parte de arriba si prefieres.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
      <div v-else-if="leads.length <= 0">
        <v-card width="100vw" height="90vh" elevation="0">
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col cols="12" justify="center" align="center">
                <div class="title">
                  Vaya, parece que no hay Leads por mostrar.
                </div>
              </v-col>
              <v-col cols="12" justify="center" align="center">
                <div class="subtitle-2 grey--text">
                  No se encontraron Leads, intenta recargando la pagina.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
      <v-data-table
        v-else
        id="leadsTable"
        v-model="selectedItems"
        :headers="headers"
        :item-key="'_id'"
        :items="filterLeadsSearchedSt ? leadsSearchedSt : leads"
        :page="page"
        :items-per-page="filterLeadsSearchedSt ? 10 : itemsPerPage"
        class="elevation-2 mx-4 mb-16"
        loading-text="Cargando leads ..."
        :options.sync="options"
        :show-select="
          role === 'admin' || role === 'marketing' || role === 'marketingv2'
        "
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            fab
            v-if="role !== 'marketing'"
            elevation="0"
            color="blue lighten-5"
            small
            class="text-center button__detail"
            @click="setActualLead(item._id)"
          >
            <v-icon color="cyan darken-4">
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.contact_lead.name`]="{ item }">
          <div class="text-caption text--secondary">
            {{ item.created_at | dateFormat(true) }}
          </div>
          <v-edit-dialog
            v-if="role === 'admin' || role === 'broker'"
            save-text="Guardar"
            cancel-text="Cancelar"
            large
            @save="
              updateLead(item._id, item.contact_lead._id, {
                name: name
              })
            "
            :return-value.sync="name"
            @cancel="cancel"
            @open="open"
            @close="dialogClosed()"
          >
            <div
              v-if="item && item.contact_lead"
              v-bind:class="item_row_background(item)"
              class="text-body-1"
              @click="setComputedName(item.contact_lead.name)"
            >
              {{ item.contact_lead.name }}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">Actualizar</div>
              <v-text-field
                v-model="name"
                placeholder="Ingresa el nombre"
                single-line
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <div
            v-else
            v-bind:class="item_row_background(item)"
            class="text-body-1"
          >
            {{ item.contact_lead.name }}
          </div>
        </template>
        <template v-slot:[`item.contact_lead.phone`]="{ item }">
          <v-edit-dialog
            save-text="Guardar"
            cancel-text="Cancelar"
            v-if="item && showInfoDev(role)"
            :return-value.sync="phone"
            large
            @save="
              updateLead(item._id, item.contact_lead._id, {
                contact_lead_phone: formatPhone(phone)
              })
            "
            @cancel="cancel"
            @open="open"
            @close="dialogClosed()"
          >
            <div
              @click="setComputedPhone(item.contact_lead.phone)"
              v-bind:class="item_row_background(item)"
              class="font-size-table-row"
            >
              <div v-if="item.contact_lead.phone">
                <v-menu
                  open-on-hover
                  offset-y
                  top
                  color="primary"
                  min-width="10"
                  nudge-top="5"
                  close-delay="500"
                  open-delay="300"
                >
                  <template v-slot:activator="{ on, attrs, value }">
                    <div v-on="on" v-bind="attrs">
                      {{ item.contact_lead.phone }}

                      <v-icon v-if="value" small>
                        mdi-pencil
                      </v-icon>
                    </div>
                  </template>
                  <v-row class="px-4">
                    <v-btn
                      class="mx-1"
                      icon
                      @click="
                        actionTootip(
                          'whatsapp',
                          item.contact_lead.phone,
                          item._id,
                          item.contact_lead._id
                        )
                      "
                    >
                      <v-icon size="20">
                        mdi-whatsapp
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      icon
                      @click="
                        actionTootip(
                          'call',
                          item.contact_lead.phone,
                          item._id,
                          item.contact_lead._id
                        )
                      "
                    >
                      <v-icon size="19">
                        mdi-phone
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      icon
                      @click="
                        actionTootip(
                          'copy',
                          item.contact_lead.phone,
                          item._id,
                          item.contact_lead._id
                        )
                      "
                    >
                      <v-icon size="20">
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-menu>
              </div>
              <div v-else class="grey--text">
                -
              </div>
            </div>
            <template v-slot:input>
              <div class="mt-4 title">Actualizar</div>
              <v-text-field
                v-model="phone"
                placeholder="Ingresa el teléfono"
                single-line
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.contact_lead.email`]="{ item }">
          <v-edit-dialog
            save-text="Guardar"
            cancel-text="Cancelar"
            v-if="item && showInfoDev(role)"
            :return-value.sync="email"
            large
            @save="
              updateLead(item._id, item.contact_lead._id, {
                contact_lead_email: email
              })
            "
            @cancel="cancel"
            @open="open"
            @close="dialogClosed()"
          >
            <div
              v-bind:class="item_row_background(item)"
              class="font-size-table-row"
              @click="setComputedEmail(item.contact_lead.email)"
            >
              <div v-if="item.contact_lead.email">
                <v-menu
                  open-on-hover
                  top
                  offset-y
                  color="primary"
                  min-width="10"
                  nudge-top="5"
                  close-delay="500"
                  open-delay="300"
                >
                  <template v-slot:activator="{ on, attrs, value }">
                    <div v-on="on" v-bind="attrs">
                      {{ item.contact_lead.email }}
                      <v-icon v-if="value" small>
                        mdi-pencil
                      </v-icon>
                    </div>
                  </template>
                  <v-row class="px-4 ">
                    <v-btn
                      class="mx-1"
                      icon
                      @click="
                        actionTootip(
                          'email',
                          item.contact_lead.email,
                          item._id,
                          item.contact_lead._id
                        )
                      "
                    >
                      <v-icon size="20">
                        mdi-email
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      icon
                      @click="
                        actionTootip(
                          'copy',
                          item.contact_lead.email,
                          item._id,
                          item.contact_lead._id
                        )
                      "
                    >
                      <v-icon size="20">
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </v-row>
                </v-menu>
              </div>
              <div v-else class="grey--text">
                -
              </div>
            </div>
            <template v-slot:input>
              <div class="mt-4 title">Actualizar</div>
              <v-text-field
                v-model="email"
                placeholder="Ingresa el Correo"
                single-line
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.zones`]="{ item }">
          <div v-if="item && item.zones" class="font-size-table-row">
            {{ item.zones }}
          </div>
          <div v-else class="font-size-table-row">
            -
          </div>
        </template>
        <template v-slot:[`item.contact`]="{ item }">
          <div
            v-if="item && item.contact && item.contact.source"
            class="font-size-table-row"
          >
            <v-row justify="start" align="center">
              <div class="pa-1 blue lighten-5 rounded-circle">
                <v-img
                  contain
                  :src="
                    item.contact.source.media
                      ? item.contact.source.media.src
                      : ''
                  "
                  width="25px"
                  height="25px"
                >
                </v-img>
              </div>
              <div class="pl-1">
                {{ item.contact.source.name }}
              </div>
            </v-row>
          </div>
          <div v-else class="font-size-table-row">
            -
          </div>
        </template>
        <template v-slot:[`item.internal_broker`]="{ item }">
          <lead-assign :lead="item" />
        </template>
        <template v-slot:[`item.phase`]="{ item }">
          <lead-select-phase :lead="item" />
        </template>
        <template v-slot:[`item.postponed.date_at`]="{ item }">
          <lead-postpone-action
            :postponed="item.postponed ? item.postponed.date_at : ''"
            :leadId="item._id"
            :lead="item"
            @updateDate="date => (item.postponed.date_at = date)"
          />
        </template>
        <template v-slot:[`item.multi_sales_opportunities`]="{ item }">
          <Tags-And-Create
            :itsImmediate="itsImmediate(item.created_at, item.reviewed)"
            :brex="item.contact_external_broker_id ? true : false"
            :lead="item"
          />
        </template>
        <template v-slot:footer>
          <v-row
            class="pagination-footer ma-0 pa-0 py-2 px-5"
            align="center"
            justify="end"
          >
            <span class="caption">Leads por página</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="filterLeadsSearchedSt"
                  dark
                  text
                  color="primary"
                  class="ml-1"
                >
                  {{ leadsSearchedSt.length }}
                </v-btn>
                <v-btn
                  v-else
                  dark
                  text
                  color="primary"
                  class="ml-0 caption"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray(
                    filterLeadsSearchedSt
                      ? leadsSearchedSt.length
                      : leadsTableFooterSt.total
                  )"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <span class="mr-4 caption">
              {{ getTextFooter() }}
            </span>
            <v-btn
              icon
              color="primary"
              class="mr-1"
              small
              :disabled="page === 1"
              @click="previousPage()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              v-if="filterLeadsSearchedSt"
              icon
              color="primary"
              class="ml-1"
              small
              :disabled="
                page === Math.ceil(leadsSearchedSt.length / itemsPerPage)
              "
              @click="nextPage()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              color="primary"
              class="ml-1"
              small
              :disabled="page === Math.ceil(leads.length / itemsPerPage)"
              @click="nextPage()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </div>
    <lead-detail-modal
      ref="leadDetailModal"
      :page="'table'"
      :dialog="isDialogActive"
      @setDialog="setDialog"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import moment from "moment";
moment.locale("es");

export default {
  components: {
    LeadSearchField: () => import("@/components/lead/LeadSearchField.vue"),
    LeadPostponeAction: () => import("@/components/lead/LeadPostpone.vue"),
    LeadDuplicates: () => import("@/components/lead/LeadDuplicates.vue"),
    LeadDetailModal: () => import("@/components/lead/LeadDetailModal.vue"),
    LeadsReassigned: () => import("@/components/lead/LeadsReassigned.vue"),
    LeadExportClipboardButton: () =>
      import("@/components/lead/LeadExportClipboardButton"),

    LeadAssign: () => import("@/components/lead/LeadAssign.vue"),
    LeadSelectPhase: () => import("@/components/lead/LeadSelectPhase.vue"),
    TagsAndCreate: () => import("@/components/lead/components/IconTagsLead.vue")
  },
  data() {
    return {
      selectedAllLeads: false,
      page: 1,
      itemsPerPage: 10,
      menusLinks: [],
      menusContactLinks: [],
      role: localStorage.getItem("user_role"),
      user: null,
      publicPath: process.env.BASE_URL,
      headers: [
        {
          text: "",
          class: "font-size-table-header",
          value: "actions",
          sortable: false
        },
        {
          text: "Nombre",
          class: "font-size-table-header ",
          value: "contact_lead.name",
          width: 170
        },
        {
          text: "Teléfono",
          class: "font-size-table-header ",
          value: "contact_lead.phone"
        },
        {
          text: "Correo",
          class: "font-size-table-header ",
          value: "contact_lead.email"
        },

        {
          text: "Llegó por",
          class: "font-size-table-header ",
          value: "zones"
        },
        {
          text: "¿Cómo nos encontró?",
          class: "font-size-table-header ",
          value: "contact",
          width: 170,
          sortable: false
        },
        {
          text: "Asignado a",
          class: "font-size-table-header ",
          value: "internal_broker",
          sortable: false
        },
        {
          text: "Status",
          class: "font-size-table-header ",
          value: "phase"
        },
        {
          text: "Siguiente acción",
          class: "py-4 font-size-table-header",
          value: "postponed.date_at"
        },
        {
          text: "Tags",
          class: "py-4 font-size-table-header ",
          value: "multi_sales_opportunities",
          width: 180
        },
        {
          text: "Idioma",
          class: "py-4 font-size-table-header",
          value: "contact.language"
        }
      ],
      options: {},
      snack: false,
      snackColor: "",
      snackText: "",
      isDialogActive: false,
      name: "",
      email: "",
      phone: ""
    };
  },
  watch: {
    filterLeadsSearchedSt(newValue) {
      if (!newValue) {
        this.itemsPerPage = 10;
      }
    },
    leads(newValue) {
      for (let i = 0; i < newValue.length; i++) {
        this.menusLinks.push(false);
        this.menusContactLinks.push(false);
      }
    },
    leadProfile(newValue) {
      let profileNumber = 0;
      switch (newValue) {
        case "A":
          profileNumber = 3;
          break;
        case "B":
          profileNumber = 2;
          break;
        case "C":
          profileNumber = 1;
          break;
        case "D":
          profileNumber = 0;
          break;
        default:
          profileNumber = 0;
          break;
      }
      this.slider = profileNumber;
    },
    itemsPerPage(newValue) {
      if (newValue != this.options) {
        this.fetchLeadsleadsPaginationSt({
          filter: this.filtersSelectedSt,
          page: 0,
          limit: newValue,
          footer: null
        });
        this.options.itemsPerPage = newValue;
      }
    },
    options: {
      handler() {
        //this.getDataFromApi();
        this.changePage(this.options);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      loading: state => state.leads.loading,
      lead: state => state.leads.actualItem,
      showLeadsDuplicates: state => state.leads.filterShowLeadsDuplicates,
      leadsPaginationLoadingSt: state => state.leads.leadsPaginationLoading,
      leadsTableFooterSt: state => state.leads.leadsTableFooter,
      leadsSearchedSt: state => state.leads.leadsSearched,
      filterLeadsSearchedSt: state => state.leads.filterLeadsSearched,
      filtersSelectedSt: state => state.leads.filtersSelected,
      someFilterSelected: state => state.leads.filterSomeFilterSelected
    }),
    ...mapGetters("leads", {
      leads: "filteredLeads"
    }),
    selectedItems: {
      get() {
        return this.$store.state.leads.selectedItems;
      },
      set(newValue) {
        this.$store.commit("leads/setSelectedItems", newValue);
      }
    },
    leadProfile: {
      get() {
        if (this.lead && this.lead.profile) return this.lead.profile;
        return "";
      },
      set: function(newValue) {
        this.$store.commit("leads/updateActualLead", {
          profile: newValue
        });
      }
    }
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD-MMM-YYYY h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    },
    percentageFormat(percentage) {
      if (!percentage || isNaN(percentage)) return 0;
      let percentageParsed = parseInt(percentage);
      return percentageParsed;
    }
  },
  async mounted() {
    await this.fetchGetLeadsFiltersSt();
    // bajamos el limite a 10 para evitar posibles duplicidades
    this.fetchLeadsleadsPaginationSt({
      filter: this.filtersSelectedSt,
      page: 0,
      limit: 10,
      footer: null
    });
  },
  beforeDestroy() {
    this.setLeadsTableFooterSt({
      page: 0,
      pages: 0,
      total: 0
    });
  },
  created() {},
  methods: {
    ...mapActions({
      fetchUpdatelLead: "leads/fetchUpdatelLead",
      fetchActualLead: "leads/fetchActualLead",
      fetchLeadsleadsPaginationSt: "leads/fetchLeadsPagination",
      fetchGetLeadsFiltersSt: "leads/fetchGetLeadsFilters"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading",
      updateActualLead: "leads/updateActualLead",
      setLeadsTableFooterSt: "leads/setLeadsTableFooter",
      setLeadsPaginationSt: "leads/setLeadsPagination",
      setFiltersSelectedSt: "leads/setFiltersSelected",
      setFiltersSelectedChangedSt: "leads/setFiltersSelectedChanged",
      setLeadsPaginationClearSt: "leads/setLeadsPaginationClear",
      setSecondaryLead: "leads/setSecondaryLead",
      setLeadsDuplicados: "leads/setLeadsDuplicados"
    }),
    actionTootip(actions, value, leadId, contactLeadId) {
      const medium = () => {
        switch (actions) {
          case "whatsapp":
            return "Whatsapp";
          case "email":
            return "Email";
          case "call":
            return "Llamada";
          default:
            return null;
        }
      };

      if (medium()) {
        this.fetchUpdatelLead({
          leadId,
          contactLeadId,
          lead: { contacted: medium() }
        });
      }
      switch (actions) {
        case "whatsapp":
          window.open(
            `//api.whatsapp.com/send?phone=+${value}&text=+${value}`,
            "_blank"
          );
          break;
        case "email":
          window.open(`mailto:${value}`, "_blank");
          break;
        case "call":
          window.open(`tel://${value}`, "_blank");
          break;
        default:
          navigator.clipboard.writeText(value);
          break;
      }
    },
    selectAllLeads(value) {
      this.$refs.refLeadReassigned.selectedAll(value);
    },
    updateItemsPerPage(num) {
      this.itemsPerPage = num;
    },
    changePage(options) {
      this.page = options.page;
      this.itemsPerPage = options.itemsPerPage;
      let currentLeads = options.page * options.itemsPerPage;
      let lessLeads = this.leads.length - currentLeads;
      if (
        this.leadsTableFooterSt.page + 1 <
        Math.ceil(this.leadsTableFooterSt.pages)
      ) {
        if (this.leads && this.leads.length > 0 && lessLeads <= 50) {
          if (!this.leadsTableFooterSt.more) {
            this.leadsTableFooterSt.more = true;
            this.leadsTableFooterSt.page = this.leadsTableFooterSt.page + 1;
            this.setLeadsTableFooterSt(this.leadsTableFooterSt);
            if (this.itemsPerPage === this.leadsTableFooterSt.total) {
              this.setLeadsPaginationSt([]);
              this.fetchLeadsleadsPaginationSt({
                filter: this.filtersSelectedSt,
                page: 0,
                limit: this.itemsPerPage,
                footer: null
              });
            } else {
              //TODO: Aqui esta el problema del paginado
              this.fetchLeadsleadsPaginationSt({
                filter: this.filtersSelectedSt,
                page: this.leadsTableFooterSt
                  ? this.leadsTableFooterSt.page
                  : 0,
                // tener cuidado con este paginado
                limit: this.itemsPerPage,
                footer: this.leadsTableFooterSt
              });
            }
          }
        }
      }
    },
    getTextFooter() {
      let c = this.itemsPerPage * this.page;
      let f = c + 1;
      if (this.filterLeadsSearchedSt) {
        if (
          this.page ===
          Math.ceil(this.leadsSearchedSt.length / this.itemsPerPage)
        ) {
          return `${f - this.itemsPerPage} - ${
            this.leadsSearchedSt.length
          } de ${this.leadsSearchedSt.length}`;
        } else {
          return `${f - this.itemsPerPage} - ${this.itemsPerPage *
            this.page} de ${this.leadsSearchedSt.length}`;
        }
      } else if (
        this.page === Math.ceil(this.leads.length / this.itemsPerPage)
      ) {
        return `${f - this.itemsPerPage} - ${this.leads.length} de ${
          this.leads.length
        }`;
      } else if (this.page === Math.ceil(this.leadsTableFooterSt.pages)) {
        return `${f - this.itemsPerPage} - ${this.itemsPerPage *
          this.page} de ${this.leadsTableFooterSt.total}`;
      } else {
        return `${f - this.itemsPerPage} - ${this.itemsPerPage *
          this.page} de ${this.leadsTableFooterSt.total}`;
      }
    },
    previousPage() {
      this.page = this.page !== 1 ? this.page - 1 : 1;
    },
    nextPage() {
      this.page++;
    },
    itemsPerPageArray(data) {
      let mount = [10, 25, 50];
      let final = [];
      for (let i = 0; i < mount.length; i++) {
        if (data > mount[i]) {
          final.push(mount[i]);
        }
      }
      final.push(data);
      return final;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    closeMenuAtLink(index) {
      this.menusLinks[index] = false;
    },
    closeMenuAtContactLink(index) {
      this.menusContactLinks[index] = false;
    },
    showInfoDev(role) {
      if (role === null || role === undefined || role === "") {
        return true;
      } else if (role === null || role === undefined) {
        return true;
      } else {
        return (
          role !== "developer" && role !== "marketing" && role !== "marketingv2"
        );
      }
    },
    onCloseReassignLeadModel(val) {
      if (val) {
        this.selectedItems = [];
      }
    },
    consultLeads() {
      let self = this;
      self.setLoading(true);
      setTimeout(() => {
        setTimeout(() => {
          self.setLoading(false);
        }, 1000);
      }, 1500);
    },
    formatPhone(phone) {
      if (phone) {
        return phone.includes("+")
          ? `+${phone.replace(/\D/g, "")}`
          : phone.replace(/\D/g, "");
      } else {
        return "";
      }
    },
    updateLeadList() {
      this.consultLeads();
    },
    updateLead(leadId, contactLeadId, dataLead) {
      //Obtenemos el lead que se esta modificando
      //Find permite actualizar el objeto y a su vez el objeto en su array
      //No hay necesidad de actualizar el array
      //find es una extension del array
      const lead = this.leads.find(lead => lead._id === leadId);
      if (dataLead.name) {
        //Si el valor existe y es igual retornamos la funcion para que no haga la actualización
        if (lead.contact_lead.name === dataLead.name) {
          return true;
        }
        lead.contact_lead.name = dataLead.name;
      }

      if (dataLead.contact_lead_email) {
        if (lead.contact_lead.email === dataLead.contact_lead_email) {
          return true;
        }
        lead.contact_lead.email = dataLead.contact_lead_email;
      }
      if (dataLead.contact_lead_phone) {
        if (lead.contact_lead.phone === dataLead.contact_lead_phone) {
          return true;
        }
        lead.contact_lead.phone = dataLead.contact_lead_phone;
      }
      //this.lead.contact_lead.name = dataLead.name;
      this.handleUpdateLead(leadId, contactLeadId, dataLead);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    isWhatsappUrl(url) {
      return url.includes("rply.link");
    },
    openLink(url) {
      this.cancel();
      window.open(url);
      this.cancel();
    },
    handleUpdateLead(leadId, contactLeadId, dataLead) {
      // una vez cambiado el lead actual, actualizar el valor en Vuex
      let data = {
        leadId,
        contactLeadId,
        lead: dataLead
      };

      let self = this;
      this.$snotify.async(
        "Actualizando lead",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.fetchUpdatelLead(data)
                .then(() => {
                  resolve({
                    title: "Lead editado",
                    body: "El lead se ha editado correctamente.",
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema actualizar el lead. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  setTimeout(() => {
                    self.setLoading(false);
                  }, 1000);
                });
            }, 500);
          })
      );
    },
    // end listeners
    priceFormat(price, currency) {
      if (parseFloat(price) <= 0 || isNaN(price) || !price) {
        return "-";
      }
      let priceFormatted =
        "$" +
        parseFloat(price)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (currency) priceFormatted.concat(" " + currency);
      return priceFormatted;
    },

    async setActualLead(leadId) {
      let self = this;
      self.setLoading(true);
      self.setDialog(true);
      // resetear leads seleccionados (checkboxes)
      self.selectedItems = [];
      //this.$refs.leadDetailModal.getTrafficData();
      await this.fetchActualLead(leadId);
      // Se hace este parseo para que cuando mute el lead, no cambie el secundario
      let data = JSON.parse(JSON.stringify(this.lead));
      this.$store.commit("leads/setSecondaryLead", data);
      self.setLoading(false);
    },
    setDialog(isActive) {
      this.isDialogActive = isActive;
      if (!isActive) {
        this.$store.commit("leads/setLeadsDuplicados", {});
      }
    },
    item_row_background(item) {
      return item.IsDuplicated === true ? "row-repeat-lead" : "";
    },
    itsImmediate(created, reviewed) {
      if (reviewed) {
        if (reviewed.is_reviewed != null && reviewed.is_reviewed) {
          var diff = Math.abs(new Date(reviewed.date_at) - new Date(created));
          const diffMinutes = Math.floor(diff / 1000 / 60);
          return diffMinutes <= 5;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    setComputedName(newValue) {
      this.name = newValue;
    },
    setComputedEmail(newValue) {
      this.email = newValue;
    },
    setComputedPhone(newValue) {
      this.phone = newValue;
    },
    dialogClosed() {
      this.name = "";
      this.email = "";
      this.phone = "";
    }
  }
};
</script>
<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(204, 204, 204, 0.081);
}
body tr:hover td {
  background-color: rgba(142, 188, 241, 0.322);
}

body tr:hover td {
  background-color: rgba(142, 188, 241, 0.322);
}

td {
  /* background-color: rgba(142, 188, 241, 0.322); */
  border-bottom: #ff4500 !important;
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
  /* padding: 0.25rem !important; */
}

body tr:nth-of-type(odd):hover td {
  background-color: rgba(142, 188, 241, 0.322);
}

.font-size-table-header {
  font-size: 0.75rem !important;
}
.font-size-table-row {
  font-size: 14px !important;
}

.lead-phase-badge.discarded,
.v-chip.v-chip--outlined.v-chip.v-chip.discarded {
  background-color: #eee !important;
}
.lead-profile-badge.profile-a {
  background-color: #008000;
}
.lead-profile-badge.profile-b {
  background-color: #ffa500;
}
.lead-profile-badge.profile-c {
  background-color: #2e6cf1;
}
.lead-profile-badge.profile-d {
  background-color: #a0a0a0;
}
.VueTables__search,
.VueTables__limit {
  display: inline-block;
  padding: 12px 0;
  margin-bottom: 0;
}
.VueTables__limit {
  float: right;
}

.lead-budget {
  font-size: 14px;
  color: #008000;
}

.pagination-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}
.text-ellips {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button__detail {
  padding: 0.25rem !important;
}
</style>
